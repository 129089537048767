import React from "react";
import bannerImg from "./../images/blackMagic.jfif";
import bannerImg1 from "./../images/shiva-2.jpg";

const Blackmagic = () => {
  return (
    <div>
      {/* <section className="banner-vastu">
        <div className="hero-center container color-white">
          <article className="hero-info">
            <h1>Removal of black magic</h1>
            <p>
              Removing Black magic will destroy the negative spirits present in the human body or the place. This will unblock all the difficulties in life and make healthy and wealthy family. 
            </p>
          </article>
        </div>
      </section> */}
      <section className="banner-healing">
        <div className="row color-white">
          {/* <article className="hero-info"> */}
          <div className="col-md-8 heading-content">
              <h1>Removal of black magic</h1>
              <p>
              Removing Black magic will destroy the negative spirits present in the human body or the place. This will unblock all the difficulties in life and make healthy and wealthy family. 
              </p>
            </div>
            <div className="col-md-4 image-right">
              <img src={bannerImg} height="300" alt=""></img>
            </div>

          {/* </article> */}
        </div>
      </section>
      <div className="image-mobile text-center">
        <img src={bannerImg} height="300" alt=""></img>
      </div>
      <section className="container">
        <div class="row">
          <div class="col-xl-6">
            <h3>How bad is black magic:</h3>
            <p>
            Black magic is the greatest curse to the mankind, many evil forces uses this technique to pull down the human being, house or office spaces mentally and physically. They use evil spirits or the negative forces remotely and send to the target person. When these forces are entered into the body or places they will destroy soul and human behaviors, this will cause many serious issues and sometimes it leads to death. There are many forms of the Blackmagic like sending the evil spirits to the human body, locking the soul, sending the evil soul to the home or work places. Ultimately, all forms of black magic will hurt human beings both physically and mentally. 
            </p>
            <p>
            There are many adverse effects of black magic, this will not only attract the negativity but also transfers to the offspring’s and next generation. Soul will be locked and damaged by the evil spirits and they will take the human being to their control. Sometimes, even without the black magic evil spirits will get into the human body, home or work place. They may be the ancestors or relatives or unfulfilled souls finding a human body to fulfill there demands. Example: A person who has died in an accident, but when he or she were living they wanted to fulfil some demands when the person died without fulfilling the demand the soul will find a weak body and join that soul and try to fulfil the demands. 
            </p>
          </div>
          <div class="col-xl-6 text-center">
            <img src={bannerImg1} height="620" alt=""></img>
          </div>
        </div>
        <h3>Benefits of White magic:</h3>

        <p>
        Kishore guruji with the help of Aghoris or Aghori in Kashi / Varanasi have practiced many techniques of removing black magic also called as white magic. There are different types of remedies available to remove the black magic, these procedures are conducted physically with the help of many homa or yagna or fire ritual. Here the aghoris conduct many rituals with the help of affected person photo. They will pull back the evil soul and black magic in the human body, home and work places and also all types of evil souls are removed. According to the ancient Hindu practices these procedures are conducted by the aghoris who are devotees of “Lord Shiva”. They will also lock the evil forces so they will not cause any trouble in future.
        </p>
        <p>
        These practices are conducted remotely no physical presence is required, as the aghoris or aghori are the best people in this planet have the capability to remove the black magic in the future. 
        </p>
      </section>
    </div>
  );
};

export default Blackmagic;
