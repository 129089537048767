import React from "react";
import bannerImg from "./../images/symbols.jpeg";
import bannerImg1 from "./../images/symbol1.png";
import bannerImg2 from "./../images/symbol2.png";
import bannerImg3 from "./../images/symbol3.png";
import bannerImg4 from "./../images/symbol4.png";
import bannerImg5 from "./../images/symbol5.png";

import bannerImg6 from "./../images/symbol6.png";
import bannerImg7 from "./../images/symbol7.png";
import bannerImg8 from "./../images/symbol8.png";

const Symbols = () => {
  return (
    <div className="vastu">
      {/* <section className="banner-vastu">
        <div className="hero-center container color-white">
          <article className="hero-info">
            <h1>Spiritual symbols</h1>
            <p>
              Did you know that 80% of problems in life have a spiritual root cause? The stubborn problems in your life are most likely to have a spiritual root cause. Find solutions to your problems here.
            </p>
          </article>
        </div>
      </section> */}
      <section className="banner-healing">
        <div className="row color-white">
          {/* <article className="hero-info"> */}
          <div className="col-md-10 heading-content">
            <h1>Spiritual symbols</h1>
            <p>
              ‘OM’ is one of the known popular symbols, similarly our ancient Saints & Yogis have created many powerful symbols for different purposes like ‘Halu Halu’, ‘Cho khu Rai’ etc.
            </p>
          </div>
          <div className="col-md-2 image-right">
            <img src={bannerImg} height="300" alt=""></img>
          </div>

          {/* </article> */}
        </div>
      </section>
      <div className="image-mobile text-center">
        <img src={bannerImg} height="300" alt=""></img>
      </div>
      <section className="container">
        <div class="row">
          <div class="col-xl-12">
            <h3>What is Spiritual symbols</h3>
            <p>
              There are many spiritual symbols used by our yogis and gurus from ancient times to suppress negative forces in both human body and living place. These symbols have capability to enhance positivity forces inside human beings and home. Spiritual symbols are a form of drawings or pictures which has logical meanings in its every form. There are many forms of symbols used in different places and different purposes, either it can be written on paper, metal or even on the air. Ultimate goal of the symbol is to decrease the chances of vulnerable.
            </p>
            <h3>How it is unique: </h3>
            <p>
              While we perform our everyday duties like walking, talking, sitting infront of computer or business places there are many forms of evil behavior or evil eye we encounter from other human beings this will cause many issues in a day to day life. Example if you are dressed up nicely and you walk into a relative place and they put negative thoughts or evil eye on you this will disturb your chakras or soul or kundali. As the result you will face turmoil. Spiritual symbols are the best way to protect from know or unknow negative forces in your day to day life. Example: every morning you can apply “RAHU” and “HALU HALU” symbol to your body and the living place this will destroy the evil forces and increases the positivity.
            </p>
          </div>
          <div class="col-xl-3 text-center image-block">
            <img src={bannerImg1} height="300" alt=""></img>
            <span>OM Chakra</span>
          </div>
          <div class="col-xl-3 text-center image-block">
            <img src={bannerImg2} height="300" alt=""></img>
            <span>Jana Akarshana</span>
          </div>
          <div class="col-xl-3 text-center image-block">
            <img src={bannerImg3} height="300" alt=""></img>
            <span>Maha Sakthi Chakri</span>
          </div>
          <div class="col-xl-3 text-center image-block">
            <img src={bannerImg4} height="300" alt=""></img>
            <span>Dhana Ruksha</span>
          </div>
          <div class="col-xl-3 text-center image-block">
            <img src={bannerImg5} height="300" alt=""></img>
            <span>Dhana Akarshana Chakra</span>
          </div>
          <div class="col-xl-3 text-center image-block">

            <img src={bannerImg8} height="300" alt=""></img>
            <span>Brahma Chakra</span>
          </div>
          <div class="col-xl-3 text-center image-block">
            <img src={bannerImg7} height="300" alt=""></img>
            <span>Vishnu Chakra</span>
          </div>
          <div class="col-xl-3 text-center image-block">
            <img src={bannerImg6} height="300" alt=""></img>
            <span>Shiva Chakra</span>
          </div>
        </div>
        <h3>Benefits of Spiritual symbols:</h3>

        <p>
          Another great gift to the man kind is using spiritual symbol in meditation. There are many symbols which enhances the power of meditation. While meditating, a normal people concentrate either on body, soul or breathing. When you apply “OM” and “CHOKRE” symbol before meditation, the power of meditation is benefitted 100 times than the normal meditation. The process of applying symbols is very easy and it takes less than 30 seconds for each symbol. Also applying spiritual symbol doesn’t need any additional instruments or materials.
        </p>

        <p>P.S: Spiritual symbols doesn’t need any additional investment, power or material. Few spiritual symbols are: OM, CHOKRE, HALU HALU, RAHU, BHOGRE, ARIVU etc.</p>
      </section>
    </div>
  );
};

export default Symbols;
