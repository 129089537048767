import React from "react";
import bannerImg1 from "./../images/kishoresir.jpeg";
import bannerImg from "./../images/aboutus.jpg";

const AboutUsPage = () => {
  return (
    <div>
    <section className="banner-healing">
        <div className="row color-white">
          <div className="col-md-6 heading-content">
              <h1>About Us</h1>
              {/* <p>
              Spiritual healing is a powerful method to heal many known and unknown disease in body. This is done remotely by sending the healing or positive energy to the person. 
              </p> */}
            </div>
            <div className="col-md-6 image-right">
              <img src={bannerImg} height="300" alt=""></img>
            </div>
        </div>
      </section>
    <section className="container">
      <div class="col-xs-12 row">
        <div class="col-xl-8 image-block">
          <p>
            Kishore Guruji is one of India's most renowned Sidda yogis. He uses cosmic and spiritual energy to heal people around the world. He spent 15+ years practising Sidda Yoga, meditating in the middle of the forest, flowing river water, and mountains in different parts of India. With his immense knowledge and power, Guruji today is an expert in spiritual healing, spiritual vastu, and correcting the auras and chakras in the human body. Also, Guruji uses both spiritual and scientific instruments like Aura scanner to identify the deepest root problems and provide an accurate solution. All his solutions will have a long-lasting effect, even for the next generations.
          </p>
          <p>
            Kishore Guruji, who is an expert in past-life regression therapy, removes doshas and karmas. He has been studying the relationship between the body, mind, and soul for the past 15 years. He has created and developed a number of spiritual healing and siddha practices. His straightforward manner and firmly held views have inspired huge numbers of people to reconsider their opinions. People have benefited from his insight and expertise as they pursued personal development and fulfilment in life. He is an expert in spiritual healing and passionate about advancing holistic healing. He has been using a combination of energy healing and other potent healing techniques to treat people as part of his spiritual journey. Additionally, he trained many healers who are now serving society selflessly.
          </p>
        </div>
        <div class="col-xl-4 text-center image-block">
          <img class="image-clip" src={bannerImg1} height="450" alt=""></img>
        </div>
        <div class="col-xl-12 image-block">
        <p>
            Subsequently, started to spread this information by giving TV shows and knowledge sharing sessions on the study of spirituality. People started practising spirituality because it was presented in such a straightforward, rational, and scientific way, and they noticed an immediate improvement in their lives. He observed an immediate improvement in the cure rate when he applied the spiritual practises. He also saw that the study of spirituality and cosmic energy was a discipline that would benefit all of humanity.
          </p>
        </div>
      </div>

    </section>
    </div>
  );
};

export default AboutUsPage;
