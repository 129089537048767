import React from "react";

const Layouttwo = () => {
    return (
        <section>
            <div className="container text-center">
                <div className="row">
                    {/* <div className="col-sm-6">
                        <img className="layout-img" src="https://www.trimurty.com/blog/wp-content/uploads/2016/11/huhfdsf-1.jpg" alt="" data-image-width="810" data-image-height="1080" />
                    </div> */}
                    <div className="col-sm-6 padding-zero">
                        <iframe src="https://www.youtube.com/embed/u7_VOnUZAPM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>

                    <div className="col-sm-6 content-block">
                        <h2 className="content-heading"> Why Thrikaal Spiritual Healing</h2>
                        <h6 className="content-text"> Exorcism, Feng Shui and other common methods to overcome a haunting have limitations. Spiritual practice of residents is the most effective and sustainable way to cleanse a home at a spiritual level.</h6>
                        <button className="btn">Know more</button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Layouttwo;
