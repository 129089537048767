import React from "react";
import Hero from "./../components/Hero";
import CardCarousel from "./../components/CardCarousel";
import Layouttwo from "./../components/Layouttwo";

const HomePage = () => {
  return (
    <>
      <Hero />
      <CardCarousel />
      <Layouttwo />
    </>
  );
};

export default HomePage;
