import React from "react";
import bannerImg from "./../images/shiva-god.jpg";
import bannerImg1 from "./../images/healing.jfif";

const Healing = () => {
  return (
    <div>
      <section className="banner-healing">
        <div className="row color-white">
          {/* <article className="hero-info"> */}
          <div className="col-md-6 heading-content">
              <h1>Spiritual Healing</h1>
              <p>
              Spiritual healing is a powerful method to heal many known and unknown disease in body. This is done remotely by sending the healing or positive energy to the person. 
              </p>
            </div>
            <div className="col-md-6 image-right">
              <img src={bannerImg} height="300" alt=""></img>
            </div>

          {/* </article> */}
        </div>
      </section>
      <div className="image-mobile text-center">
        <img src={bannerImg} height="200" alt=""></img>
      </div>
      <section className="container">
        <div class="row">
         
          <div class="col-xl-6">
            <h3>What is Spiritual healing?</h3>
            <p>
              Every human being will have a rebirth. The soul will travel from past life to present life. Every time a human being is reborn or the soul is transferred to a new body, the karma from the previous life are carried forward to the next life. Most human beings suffer from past life regression, past life karmas and Doshas (defect). When these issues carry forward to the next life, they create blockages in the present life. These issues will block personal and professional progress, financial pressure, health and mental illness. Overall, the wellbeing of a human being is blocked by these issues.
            </p>
            <p>
          The Chakras are the important elements of the human body.Our body is made up of 7 chakras, and they play a vital role in the physical, biological and psychological wellbeing of the human body. These chakras are often disturbed by many factors. When the chakras get disturbed, they will undergo severe pressure. This will directly put pressure on the mind and body.
        </p>
          </div>
          <div class="col-xl-6 text-center">
            <img src={bannerImg1} height="450" alt=""></img>
          </div>
        </div>
        <h3>How we will help you:</h3>
        <p>
          <b>Through many years of meditation and spiritual practice, Kishore guruji is mastered the art of an ancient spiritual healing. </b>He is a well renowned spiritual healer who has an ability to heal issues from past lives, shapa (bad curse), karma dosha (evil defect) and chakra balancing and activation. When the human body is spiritually healed by the yogis, it will undergo major positive changes and lead towards a successful path. Hundreds of people have benefited from the spiritual healing of Kishore Guruji and leading a happy and prosperous life.
        </p>
      </section>
    </div>
  );
};

export default Healing;
