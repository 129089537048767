import React from "react";
import bannerImg from "./../images/banner9.png";
import bannerImg1 from "./../images/names.png";

const Namesuggession = () => {
  return (
    <div>
      {/* <section className="banner-vastu">
        <div className="hero-center container color-white">
          <article className="hero-info">
            <h1>Spiritual Name Suggestion</h1>
            <p>
            Each name is unique for place or a person, when the frequency of the name is matching to the frequency of the place or person the positivity will increase. 
            </p>
          </article>
        </div>
      </section> */}
       <section className="banner-healing">
        <div className="row color-white">
          {/* <article className="hero-info"> */}
          <div className="col-md-8 heading-content">
              <h1>Spiritual Name Suggestion</h1>
              <p>
              Each name is unique for place or a person, when the frequency of the name is matching to the frequency of the place or person the positivity will increase.
              </p>
            </div>
            <div className="col-md-4 image-right">
              <img src={bannerImg} height="300" alt=""></img>
            </div>

          {/* </article> */}
        </div>
      </section>
      <div className="image-mobile text-center">
        <img src={bannerImg} height="300" alt=""></img>
      </div>
      <section className="container">
        <div class="row">
          <div class="col-xl-6">
            <h3>What is Spiritual Name:</h3>
            <p>
            Every individual place, city, forest, company, industry, or thing has a frequently used term to address it and it is called "Name". As we know, everything in this universe has a certain energy or frequency when you recite a name, which means you will emit a certain form of energy or frequency. The person or replacement who is receiving the energy will also reciprocate and emit certain forms of frequency and energy. The ultimate goal of having a name is to address a person and increase the capability of a person, company, or thought with the frequency they emit.
            </p>
            <h3>Why does it really matter:</h3>
            <p>
            When a human being, company, industry, or non-living thing radiates energy in the form of their name, it will match the inner frequency of a person. When you call a child Vishwas, the name Vishwas should match the energy of the aura chakras and Kundalini. If this happens, both the human body frequency and the name of a person have bright energy levels, so this will automatically sync with their nature. When we ourselves sync with nature, we will work at the highest frequency in this universe and attract a lot of positive energy.
            </p>
          </div>
          <div class="col-xl-6 text-center">
            <img src={bannerImg1} height="470" alt=""></img>
          </div>
        </div>
        <h3>How we suggest name spiritually:</h3>

        <p>
        Kishor Guruji uses scientific and spiritual methods to suggest a name when a child is born or even when somebody wants to start something new like an industry, company, or even a home or workplace. This method is taken through the aura scanner or a scanner that matches the energy level of the person. This is cross checked at the trans level or hypnotism level. When somebody admits a name, it should have a golden energy. When golden energy is emitted, it will sink perfectly into nature, and it will attract higher positive energy, health, wealth, and happiness.
        </p>
        <p>
        In the last 10 years, Kishore Guruji has suggested many spiritual names which match the soul and aura of the person. We have seen many businesses which are spiritually named by Kishore Guruji has made a tremendous success.
        </p>
      </section>
    </div>
  );
};

export default Namesuggession;
