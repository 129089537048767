import React from "react";
import bannerImg from "./../images/naga.png";
import bannerImg1 from "./../images/naga-dosha.jpg";

const Nagadosha = () => {
  return (
    <div>
      {/* <section className="banner-vastu">
        <div className="hero-center container color-white">
          <article className="hero-info">
            <h1>Removal of Naga Dosha</h1>
            <p>
            Naga dosha is a ben or sin by the divine Naga or sarpa to human body. Removal of Naga dosha by aghoris will give mukthi or shanti to sarpa which will lead to the successful path in life. 
            </p>
          </article>
        </div>
      </section> */}
       <section className="banner-healing">
        <div className="row color-white">
          {/* <article className="hero-info"> */}
          <div className="col-md-8 heading-content">
              <h1>Removal of Naga Dosha</h1>
              <p>
              Naga dosha is a ben or sin by the divine Naga or sarpa to human body. Removal of Naga dosha by aghoris will give mukthi or shanti to sarpa which will lead to the successful path in life.
              </p>
            </div>
            <div className="col-md-4 image-right">
              <img src={bannerImg} height="300" alt=""></img>
            </div>

          {/* </article> */}
        </div>
      </section>
      <div className="image-mobile text-center">
        <img src={bannerImg} height="300" alt=""></img>
      </div>
      <section className="container">
        <div class="row">
          <div class="col-xl-6">
            <h3>What is Naga dosha:</h3>
            <p>
            According to Hindu mythology Sarpa or Naga or Snake, is a divine symbol that represents a sacred god. Naga is considered to be a sacred god. It has got a respectable and divine position among human beings. Knowingly or Unknowingly, many human beings tend to hurt Naga and according to Hindu mythology, if anybody hurts Naga, the divine will remember it for a minimum of 12 years. Whoever hurts Naga will have to undergo a huge amount of turmoil in many forms, either physically or emotionally.
            </p>
            <p>
            If a human being kills or hurt sarpa, the soul of the hurt sarpa will get into the human soul to take revenge and it will start troubling the human being and block progress in life. Many Naga Doshas will trouble human beings in many forms. It depends on how the sarpa soul is embedded into the human soul. For example, a sarpa will go to the head position or even to the stomach or even to the leg. Wherever the sarpa is present in the human soul, that body part will undergo a huge problem. You might have seen a few people go through a lot of health issues in their lives. Even if they take any kind of treatment, the problem will still persist and not be curable through medicine. This type of problem is mostly caused by Naga dosha.
            </p>
          </div>
          <div class="col-xl-6 text-center">
            <img src={bannerImg1} height="550" alt=""></img>
          </div>
        </div>
        <h3>Benefits of removing Naga dosha:</h3>

        <p>
        Kishore Guruji with the help of an Aghori’s, they will remove Naga dosha for all human beings. Sometimes the Naga doshas will be present in the home or office. The team will conduct certain Hindu rituals like Homa Havana, which will pullback the Naga soul present in the human soul. While doing this, the Naga soul will be given Mukti or Shanti so that the hurting soul will rest in peace and will not trouble anymore. If the hurting soul is not given proper mukthi, the dosha will even be transferred to the next generations and the next generations will also face the problems.
        </p>
        <p>
        We have seen countless families who have removed their naga dosha with the help of Naga Sadhu and Aghori and are now living happy and peaceful lives. 
        </p>  
      </section>
    </div>
  );
};

export default Nagadosha;
