import React from "react";

const Contactus = () => {
    return (
        <section class="container">
            <div class="row">
                <h1 class="text-center">contact us</h1>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <h4>We'd love to hear from you!</h4>
                    </div>
                    <div class="row input-container">
                        <div class="col-xs-12">
                            <div class="styled-input wide">
                                <input type="text" required />
                                <label>Name</label>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="styled-input">
                                <input type="text" required />
                                <label>Email</label>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="styled-input">
                                <input type="text" required />
                                <label>Phone Number</label>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="styled-input wide">
                                <textarea required></textarea>
                                <label>Message</label>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="btn-lrg submit-btn">Send Message</div>
                        </div>
                    </div>
                </div>
                {/* <div class="col-md-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d599.3779981517233!2d77.56361064548933!3d12.89809734095057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15607996e723%3A0x150a9c16b724ba17!2sShravanthi%20Alpyne!5e0!3m2!1sen!2sin!4v1661437126538!5m2!1sen!2sin" width="100%" height="550" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div> */}
            </div>

        </section>
    );
};

export default Contactus;
