// import { StrictMode } from "react";
// import ReactDOM from "react-dom";
// import { AppProvider } from "./components/context";
// import App from "./App";
// import HomePage from "./pages/HomePage";
// import AboutUsPage from "./pages/AboutUsPage";
// import Navbar from "./components/Navbar";
// import Sidebar from "./components/Sidebar";
// import Footer from "./components/Footer";
// import { Route, Routes, BrowserRouter } from "react-router-dom";

// const rootElement = document.getElementById("root");
// ReactDOM.render(
//   <StrictMode>
//     <AppProvider>
//       <App />
//     </AppProvider>
//   </StrictMode>,
//   rootElement
// );

// const rootElement = document.getElementById("root");
// ReactDOM.render(
//   <BrowserRouter>
//     {/* <App/> */}
//     <Routes>
//       <Route path='/' element={<HomePage/>} />
//       <Route path='/home' element={<HomePage/>} />
//       <Route path='/about-us' element={<AboutUsPage/>} />
//     </Routes>
//     <Footer />
//   </BrowserRouter>,
//   rootElement
// );



import React from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom";
import {
  Navbar,
  ContentGroup,
  PrimaryDropdown,
  IconDropdown,
  ColumnDropdown
} from "react-responsive-nav";
import { Link } from "react-router-dom";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import Healing from "./components/Healing";
import Vastu from "./components/Vastu";
import Symbols from "./components/Symbols";
import Gemstone from "./components/Gemstone";
import Blackmagic from "./components/Blackmagic";
import Soulmate from "./components/Soulmate";
import Nagadosha from "./components/Nagadosha";
import Namesuggession from "./components/Namesuggession";
import Contactus from "./components/Contactus";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Whatsapp from "./components/Whatsapp";
// import ScrollToTop from "./components/ScrollToTop";

// import Navbar from "./components/Navbar";
// import Sidebar from "./components/Sidebar";
import bannerImg1 from "./images/thikaal_logo.png";

import "./styles.css";

const primaryDropdown = {
  title: "Services",
  links: [
    {
      link: <Link to="/healing">Spiritual Healing</Link>,
      text: "A complete payments plaform engineered for growth.",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    },
    {
      link: <Link to="/vastu">Spiritual Vastu</Link>,
      text: "A complete payments plaform engineered for growth.",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    },
    {
      link: <Link to="/symbols">Spiritual symbols</Link>,
      text: "A complete payments plaform engineered for growth.",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    },
    {
      link: <Link to="/gemstone">Gem stone consultation</Link>,
      text: "A complete payments plaform engineered for growth.",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    },
    {
      link: <Link to="/blackmagic">Removal of black magic</Link>,
      text: "A complete payments plaform engineered for growth.",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    },
    {
      link: <Link to="/soulmate">Finding Soul mate</Link>,
      text: "A complete payments plaform engineered for growth.",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    },
    {
      link: <Link to="/nagadosha">Removal of Naga dosha</Link>,
      text: "A complete payments plaform engineered for growth.",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    },
    {
      link: <Link to="/namesuggession">Spiritual Name Suggestion</Link>,
      text: "A complete payments plaform engineered for growth.",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    }
  ],
  footerLink: {
    link: <Link to="/webpack">WEBPACK IS COOL TOO</Link>,
    icon:
      "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
  }
};

const aboutUs = {
  title: "About us",
  links: [
    {
      link: <Link to="/about-us">About Us</Link>,
      text: "",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    }
  ],
  footerLink: {
    link: <Link to="/webpack">WEBPACK IS COOL TOO</Link>,
    icon:
      "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
  }
};

const home = {
  title: "About us",
  links: [
    {
      link: <Link to="/home">Home</Link>,
      text: "",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    }
  ],
  footerLink: {
    link: <Link to="/webpack">WEBPACK IS COOL TOO</Link>,
    icon:
      "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
  }
};

const contactUs = {
  title: "Contact us",
  links: [
    {
      link: <Link to="/contactus">Contact us</Link>,
      text: "",
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556303055/Portfolio/logo-elixir.svg",
      color: "rgb(103, 114, 229)"
    }
  ],
  footerLink: {
    link: <Link to="/webpack">WEBPACK IS COOL TOO</Link>,
    icon:
      "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
  }
};

const columnDropdown = {
  title: "Developers",
  color: "rgb(131,205,41)",
  primaryLink: {
    subtitle: "Start integrating Stripe's products and tools.",
    link: <Link to="/node">Node.js</Link>,
    icon:
      "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302721/Portfolio/nodejs-original.svg"
  },
  firstColumn: {
    title: "Get Started",
    links: [
      <Link to="/get-started">Get Started</Link>,
      <Link to="/get-started">Get Started</Link>,
      <Link to="/get-started">Get Started</Link>,
      <Link to="/get-started">Get Started</Link>
    ]
  },
  secondColumn: {
    title: "Popular Topics",
    links: [
      <Link to="/apple">Apple Pay</Link>,
      <Link to="/apple">Apple Pay</Link>,
      <Link to="/apple">Apple Pay</Link>,
      <Link to="/apple">Apple Pay</Link>
    ]
  },
  footerLinks: [
    {
      link: <Link to="/full-api">Full API Reference</Link>,
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302721/Portfolio/nodejs-original.svg"
    },
    {
      link: <Link to="/full-api">Full API Reference</Link>,
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302721/Portfolio/nodejs-original.svg"
    },
    {
      link: <Link to="/full-api">Full API Reference</Link>,
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302721/Portfolio/nodejs-original.svg"
    }
  ]
};

const iconDropdown = {
  title: "Company",
  color: "rgb(50, 151, 211);",
  links: [
    {
      link: <Link to="/full-api">Full API Reference</Link>,
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
    },
    {
      link: <Link to="/full-api">Full API Reference</Link>,
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
    },
    {
      link: <Link to="/full-api">Full API Reference</Link>,
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
    },
    {
      link: <Link to="/full-api">Full API Reference</Link>,
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
    },
    {
      link: <Link to="/full-api">Full API Reference</Link>,
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
    },
    {
      link: <Link to="/full-api">Full API Reference</Link>,
      icon:
        "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
    }
  ],
  footerTitle: {
    link: <Link to="/blog">FROM THE BLOG</Link>,
    icon:
      "https://res.cloudinary.com/dmjolhdaq/image/upload/v1556302786/Portfolio/webpack-original.svg"
  },
  footerLinks: [
    <Link to="/apple">A new and improved onboarding flow for Everyone</Link>,
    <Link to="/apple">A new and improved onboarding flow for Everyone</Link>,
    <Link to="/apple">A new and improved onboarding flow for Everyone</Link>
  ]
};

const authLinks = [
  <Link to="/signin">Sign In &rarr;</Link>,
  <Link to="/signup">Sign Up &rarr;</Link>
];
const mobileLinks = [
  <Link to="/pricing">Pricing</Link>,
  <Link to="/pricing">Works With Stripe</Link>,
  <Link to="/pricing">Partner Program</Link>,
  <Link to="/pricing">Documentation</Link>,
  <Link to="/pricing">About Stripe</Link>,
  <Link to="/pricing">Jobs</Link>,
  <Link to="/pricing">Newsroom</Link>,
  <Link to="/pricing">Blog</Link>
];

function App() {
  return (
    <div className="App">
      <Navbar
        duration={200}
        columnWidth="120"
        primaryDropdown={primaryDropdown}
        mobileFooterLinks={authLinks}
        mobileLinks={mobileLinks}
        breakpoint={900}
      >
         <ContentGroup title="Home" height="100" width="250">
          <PrimaryDropdown primaryDropdown={home} />
        </ContentGroup>
        <ContentGroup title={primaryDropdown.title} height="550" width="495">
          <PrimaryDropdown primaryDropdown={primaryDropdown} />
        </ContentGroup>
        <ContentGroup title={aboutUs.title} height="100" width="250">
          <PrimaryDropdown primaryDropdown={aboutUs} />
        </ContentGroup>
        <ContentGroup title="Contact Us" height="100" width="250">
          <PrimaryDropdown primaryDropdown={contactUs} />
        </ContentGroup>
        {/* <ContentGroup title={columnDropdown.title} height="408" width="440">
          <ColumnDropdown columnDropdown={columnDropdown} />
        </ContentGroup>
        <ContentGroup title={iconDropdown.title} height="442" width="420">
          <IconDropdown iconDropdown={iconDropdown} />
        </ContentGroup>
        <ContentGroup title="Sign In" height="200" width="350">
          <ul>
            <li>Create custom sign in form here</li>
            <li>Add a link to sign up here</li>
          </ul>
        </ContentGroup> */}
      </Navbar>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter>
  {/* <ScrollToTop> */}
    <Link to="/home">
      <img src={bannerImg1} className="logo" alt="phone" />
    </Link>
    {/* <App/> */}
    <Nav />
    {/* <Navbar />
    <Sidebar /> */}
    <Routes>
      <Route path='/' element={<HomePage/>} />
      <Route path='/home' element={<HomePage/>} />
      <Route path='/about-us' element={<AboutUsPage/>} />
      <Route path='/healing' element={<Healing/>} />
      <Route path='/vastu' element={<Vastu/>} />
      <Route path='/symbols' element={<Symbols/>} />
      <Route path='/gemstone' element={<Gemstone/>} />
      <Route path='/blackmagic' element={<Blackmagic/>} />
      <Route path='/soulmate' element={<Soulmate/>} />
      <Route path='/nagadosha' element={<Nagadosha/>} />
      <Route path='/namesuggession' element={<Namesuggession/>} />
      <Route path='/contactus' element={<Contactus/>} />
    </Routes>
    <Whatsapp />
    <Footer />
    {/* </ScrollToTop> */}
  </BrowserRouter>,
  rootElement
);
