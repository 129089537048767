import React from "react";
import bannerImg from "./../images/banner9.png";
import bannerImg1 from "./../images/thrishul.png";

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-center">
        {/* <article className="hero-images">
          <img src={bannerImg1} className="phone-img" alt="phone" />
        </article> */}
        <article className="hero-info">
          <h1>Cosmic way to Prosperity</h1>
          <p>
          Did you realise that spiritual issues account for 90% of life's issues? Your life's persistent issues are most likely spiritual in nature. Find answers to your issues right here.
          </p>
          <button className="btn">Know more</button>
        </article>
        <article className="hero-images">
          <img src={bannerImg1} className="phone-img banner-img" alt="phone" />
        </article>
      </div>
    </section>
  );
};

export default Hero;
