import React from "react";
import bannerImg from "./../images/gemstoness.jpeg";
import bannerImg1 from "./../images/gemstone.jpeg";

const Gemstone = () => {
  return (
    <div>
      {/* <section className="banner-vastu">
        <div className="hero-center container color-white">
          <article className="hero-info">
            <h1>Gem Stone Consultation</h1>
            <p>
            Every single individual needs a different gemstone to match frequency of the body and enhance the power. We scientifically suggest gemstones meeting each and every person frequency through aura scanner.
            </p>
          </article>
        </div>
      </section> */}
      <section className="banner-healing">
        <div className="row color-white">
          {/* <article className="hero-info"> */}
          <div className="col-md-8 heading-content">
              <h1>Gem Stone Consultation</h1>
              <p>
              Every single individual needs a different gemstone to match frequency of the body and enhance the power. We scientifically suggest gemstones meeting each and every person frequency through aura scanner.
              </p>
            </div>
            <div className="col-md-4 image-right">
              <img src={bannerImg} height="300" alt=""></img>
            </div>

          {/* </article> */}
        </div>
      </section>
      <div className="image-mobile text-center">
        <img src={bannerImg} height="300" alt=""></img>
      </div>
      <section className="container">
        <div class="row">
          <div class="col-xl-6">
            <h3>How Gem stone is identified:</h3>
            <p>
            Every human being will have around 2 watts current in their body and it will be translated as ‘AURA’, aura is a frequency or the energy emits from the body and will act as radiator to attract or ripple the other forces it may be positive or negative. It usually depicts as a form of light surrounded in the body. 
            </p>
            <p>
            The stones which are worn on the body in any forms like figure ring, ear ring, necklace, dollar or even the fancy stones. All the stones will emit certain frequency of radiation. The frequency of aura and the frequency of stone should always match and both should work as magnet to attract the positive forces. That is the significance of the gem stone. 
            </p>
          </div>
          <div class="col-xl-6 text-center">
            <img src={bannerImg1} height="350" alt=""></img>  
          </div>
        </div>
        <h3>Scientific way of gemstone suggestion:</h3>

        <p>
        The biggest mistake most of the astrologer or consultant do is, they suggest the stones based on the birth date or the Rashi or horoscope. While suggesting these gem stone they don’t test whether this stone is matching to the human body frequency and if it is acting as positive force or negative force. When a human being wore a gem stone which is not matching the frequency of the body it will disturb the existing aura or chakras and the negative forces will be attracted. This will cause severe damage to personal and professional life.
        </p>
        <h3>Benefits of right Gemstone:</h3>
        <p>
        Kishore guruji uses scientific method to suggest the gem stone according to the frequency of the human body. He uses radio frequency machine to identify the suitable gem stone matching to each and every human body. The gem stones are not only checked based on the human body frequency but also on how the gem stone is attracting positivity, health and wealth. In this process, first he will do a thorough examination of different kinds of gem stone matching the frequency. Ex: ruby, emerald, cat eye etc.,
        </p>
        <p>
        Once the gem stone is finalized. For ex: Ruby stone. There will be many Ruby stones in that suitable is selected from the lot according to our body energy which attracts. Also, spiritual symbols will be applied to the gem stone to enhance the power of the stone. This gem stone will protect the human body, aura and chakras from any negative forces and also attract positivity in life.    
        </p>
      </section>
    </div>
  );
};

export default Gemstone;
