import React from "react";
import bannerImg1 from "./../images/vastu.jpg";
import bannerImg from "./../images/vastu.jpeg";

const Vastu = () => {
  return (
    <div>
      {/* <section className="banner-vastu">
        <div className="hero-center container color-white">
          <article className="hero-info">
            <h1>Spiritual Vastu</h1>
            <p>
              Did you know that 80% of problems in life have a spiritual root cause? The stubborn problems in your life are most likely to have a spiritual root cause. Find solutions to your problems here.
            </p>
          </article>
        </div>
      </section> */}
      <section className="banner-healing">
        <div className="row color-white">
          {/* <article className="hero-info"> */}
          <div className="col-md-6 heading-content">
              <h1>Spiritual Vastu</h1>
              <p>
              Ancient way of constructing the house / complex to enhance positivity and bring prosperity and happiness. This can be done even for the already constructed house without demolishing. 
              </p>
            </div>
            <div className="col-md-6 image-right">
              <img src={bannerImg} height="300" alt=""></img>
            </div>

          {/* </article> */}
        </div>
      </section>
      <div className="image-mobile text-center">
        <img src={bannerImg} height="300" alt=""></img>
      </div>
      <section className="container">
        <div class="row">
          <div class="col-xl-6">
            <h3>What is Spiritual Vastu:</h3>
            <p>
              Spiritual Vaastu is an ancient art of balancing the positive and negative energy in nature. Through Spiritual Vastu, a home, office, commercial establishment or any structure can be balanced with both positive and negative energy. Nature has both positive and negative forces. With spiritual Vastu, we can suppress the negative energy in any place and increase the positive energy.
            </p>
            <p>
              Negative energy will be created inside a house, office, or a factory for many reasons. Like improper construction or land has impacted with negative forces or even there are too many negative elements inside the premises, it will cause a lot of disturbance for living. People who conducts any form of business when this disturbance exists they will encounter unpredicted issues in life. For example, they will get trapped into unnecessary financial losses, Health issues and mental issues also business will not grow as expected this will lead to poor quality of life.
            </p>
          </div>
          <div class="col-xl-6 text-center">
            <img className="image-fit" src={bannerImg1} height="420" alt=""></img>
          </div>
        </div>
        <h3>How Spiritual Vastu is conducted:</h3>

        <p>
          Spiritual Vaastu is one of the ancient Indian techniques where the Yogis used many symbols and forces to suppress the negative energy inside the premises and they use symbols or materials to increase the positive energy inside the premises. Spiritual Vaastu consists of placing the right symbols and the right materials inside the premises in the right direction, which enhances the positive energy. For example, placing a Sri Chakra symbol inside the house will act as a positive energy booster or placing a dhanuvruksha symbol in the front of the premises attracts money or treasure.
        </p>
        <p>
          Guruji uses the techniques like placing the pyramids, inyan symbols, chakras, kundalis and many ancient symbols to enhance the positive energy and create a healthy and wealthy life.
        </p>
      </section>
    </div>
  );
};

export default Vastu;
