import React from "react";
import bannerImg from "./../images/soulmate.jpeg";
import bannerImg1 from "./../images/soul-mate.jpg";

const Soulmate = () => {
  return (
    <div>
      {/* <section className="banner-vastu">
        <div className="hero-center container color-white">
          <article className="hero-info">
            <h1>Finding Soul mate</h1>
            <p>
            When two human souls’ frequency is matching they both become the perfect couple. the identification of matching or finding soul mate is conducted before marriage though aura scanner.
            </p>
          </article>
        </div>
      </section> */}
       <section className="banner-healing">
        <div className="row color-white">
          {/* <article className="hero-info"> */}
          <div className="col-md-8 heading-content">
              <h1>Finding Soul mate</h1>
              <p>
              When two human souls’ frequency is matching they both become the perfect couple. the identification of matching or finding soul mate is conducted before marriage though aura scanner.
              </p>
            </div>
            <div className="col-md-4 image-right">
              <img src={bannerImg} height="300" alt=""></img>
            </div>

          {/* </article> */}
        </div>
      </section>
      <div className="image-mobile text-center">
        <img src={bannerImg} height="300" alt=""></img>
      </div>
      <section className="container">
        <div class="row">
          <div class="col-xl-6">
            <h3>How it different from normal marriage match making:</h3>
            <p>
            The word Soulmate describes Two Souls male and female how they will live each other in the marital life. When both souls match each other, it will lead to a happy family and there will be less disturbance in married life. It is just not the body live each other even the soul is living together. We mentioned earlier every soul on human being will have a certain frequency while both live together a male and female their soul and body frequency should match win this match radius a perfect energy this will leads to a happy family and even the offspring will have a healthy and happy life.
            </p>
            <h3>Better accuracy than astrology / numerology etc</h3>
            <p>
            Finding a soulmate is never an easy job, most marriages happen based on the matching of horoscopes, birth date or photos.  This has been a tradition from a while where other two families look for suitable horoscopes and get to a marital state or in love marriages two people likes each other and get married. Even after many types of marriages either it may be love or arranged checking numerological astrologically and any tradition wise many marriages will fail and will not have a peace of mind in day to day life. This is the main reason for the soulmates mismatch and causes fights and misunderstanding in the life which will end up in breakup.
            </p>
          </div>
          <div class="col-xl-6 text-center">
            <img src={bannerImg1} height="580" alt=""></img>
          </div>
        </div>
        <h3>How we suggest a right soul mate</h3>

        <p>
        Kishore Guruji leads a program of finding the perfect soulmate using the scientific and spiritual method. In the scientific method a radio frequency instrument is used and in the spiritual method Trance or hypnotism is used. Matching both the soul is the process of finding the perfect vibration of auras and soul so the bonding becomes stronger. 
        </p>
        <p>
        Finding a soul mate is an easier process. It can be done from any part of the world with the help of male and female photographs. This can be done in less than 10 minutes.
        </p>
      </section>
    </div>
  );
};

export default Soulmate;
