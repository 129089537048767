import ReactWhatsapp from "react-whatsapp";

import FloatingWhatsApp from "react-floating-whatsapp";
import "react-floating-whatsapp/dist/index.css";

export default function Whatsapp() {
  return (
    <div className="Whatsapp">
      <ReactWhatsapp number="+918660666737" message="Welcome to Thrikaal" />
      <FloatingWhatsApp phoneNumber="+918660666737" />
    </div>
  );
}
